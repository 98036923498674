<template>
  <div class="reportCgicop">
    <div class="image">
      <img src="../assets/image/spacialBanner.bc11f27.png" alt="" style="width: 100%; height: 100%;">
    </div>
    <div class="layout">
      <div class="listHd clearfloat">
        <div class="fl listHdGrop">
          {{$t('m.listPageOption')}}：<span style="color: #0162b3;">&nbsp;纪检举报</span>
        </div>
      </div>
      <div class="title">中甘国际纪委网络举报中心</div> 
      <div class="content">
        <div class="content_title">我要提交纪检举报中心</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <table>
            <tr>
              <td>
                <span class="star">*</span>举报人姓名
              </td> 
              <td>
                <div >
                  <el-form-item prop="jbUserName">
                    <el-input v-model="ruleForm.jbUserName" ></el-input>
                  </el-form-item>
                </div>
              </td> 
              <td></td>
            </tr>
            <tr>
              <td>
                <span class="star">*</span>您的地址
              </td> 
              <td>
                <div >
                  <el-form-item  prop="jbUserWorkUnit">
                    <el-input v-model="ruleForm.jbUserWorkUnit"></el-input>
                  </el-form-item>  
                </div>
              </td> 
              <td></td>
            </tr>
            <tr>
              <td>
                <span class="star">*</span>被举报人姓名
              </td> 
              <td>
                <div >
                  <el-form-item prop="bjbUserName">
                    <el-input v-model="ruleForm.bjbUserName" ></el-input>
                  </el-form-item>
                </div>
              </td> 
              <td></td>
            </tr>
            <tr>
              <td>
                <span class="star">*</span>被举报人单位
              </td> 
              <td>
                <div >
                  <el-form-item  prop="bjbUserWorkUnit">
                    <el-input v-model="ruleForm.bjbUserWorkUnit"></el-input>
                  </el-form-item>     
                </div>
              </td> 
              <td></td>
            </tr>
            <tr>
              <td>
                <span class="star">*</span>举报材料
              </td> 
              <td>
                <div >
                  <el-form-item  prop="bjbUserWorkUnit">
                    <el-upload
                      class="upload-demo"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      multiple
                      :limit="3"
                      :on-exceed="handleExceed"
                      :file-list="fileList"
                      v-model="ruleForm.bjbUserWorkUnit">
                      <el-button >点击上传</el-button>
                    </el-upload>
                  </el-form-item>     
                </div>
              </td> 
              <td ><span class="star">*</span>附件大小小于20M</td>
            </tr>
            <tr>
              <td>
                <span class="star">*</span>举报标题
              </td> 
              <td>
                <div >
                  <el-form-item prop="jbTitle">
                    <el-input v-model="ruleForm.jbTitle"></el-input>
                  </el-form-item>   
                </div>
              </td> 
              <td></td>
            </tr>
            <tr>
              <td>
                <span class="star">*</span>举报内容
              </td> 
              <td>
                <div>
                  <el-form-item  prop="jbContent">
                    <el-input type="textarea" :rows="4" v-model="ruleForm.jbContent" ></el-input>
                  </el-form-item>   
                </div>
              </td> 
              <td></td>
            </tr>
            <tr>
              <td>
              </td> 
              <td>
                <div >
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                  </el-form-item>
                </div>
              </td> 
              <td></td>
            </tr>
          </table>
        </el-form>
      </div>
    </div>  
  </div>
</template>

<script>
import { addAccuseInform } from '@/api/common'
export default {
  data() {
    return {      
      fileList:[],
      mathedTitle:'在线举报',   
      code: "6666",
      identifyCodes: "1234567890",
      identifyCode: "",
      loading: false,
      ruleForm: {
        jbUserName: '',
        jbTel: '',
        jbUserWorkUnit: '',
        bjbUserName: '',
        bjbUserWorkUnit: '',
        jbTitle: '',
        jbContent: '',
        code: '',
        applicationUuid: this.webId
      },
      rules: {
        jbUserName: [
          { required: true, message: '请输入举报人姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        
        bjbUserName: [
          { required: true, message: '请输入被举报人姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        bjbUserWorkUnit: [
          { required: true, message: '请输入被举报人单位', trigger: 'blur' }
        ],
        jbContent: [
          { required: true, message: '举报内容字数过少', trigger: 'blur' },
          { min: 10, message: '举报内容字数过少', trigger: 'blur' }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],

      },
    }
  },
  computed: {
  
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    // 提交
    submitForm(formName) {
      var _this = this
      this.loading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addAccuseInform(_this.ruleForm).then(res => {
            const { code } = res
            if (code === 1) {
              this.loading = false
              this.$confirm('提交成功, 我们会及时联系您！', '提示', {
                showConfirmButton: false,
                cancelButtonText: '取消',
                type: 'success',
                center: true
              })
              .then(() =>{
              })
              .catch(() =>{
                _this.resetForm('ruleForm')
              })
            } else{
              this.loading = false
              this.$message({
                type: 'warning',
                message: '提交失败，请稍后重试!'
              })
              }
            })
           }
        
            else {
              console.log('error submit!!')
              return false
            }
          });
        },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file,) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      }
  },
}
</script>

<style lang="scss">
.listHd {
  height: 50px;
  display: flex;
  line-height: 50px;
}
.listHdGrop {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333;
    opacity: .6;
}
.reportCgicop .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
    width: 1200px;
    margin: 0 auto;
}
.reportCgicop .title {
    width: 1200px;
    background: #0162b3;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #fff;
    text-align: center;
}
.reportCgicop  .title {
    height: 50px;
    line-height: 50px;
}
.reportCgicop .content {
    width: 1171px;
    height: 948px;
    background: #fff;
    border: 2px solid #0162b3;
    margin: 4px 0 20px;
    padding: 20px 0 0 25px;
}
.reportCgicop .content_title, .content tr td {
    font-size: 16px;
    font-family: Microsoft YaHei;
}
.reportCgicop .content_title {
    width: 1135px;
    height: 50px;
    background: #3a97e5;
    font-weight: 700;
    color: #fff;
    line-height: 50px;
    padding-left: 10px;
}
.reportCgicop table {
    border-collapse: collapse;
}
.reportCgicop table > tr {
    vertical-align: middle;
}
.reportCgicop .content tr td:first-child {
    width: 170px;
    height: 80px;
}
.content tr td:nth-child(2) {
    width: 373px;
    height: 80px;
}
// .content tr td:nth-child(2) div {
//     width: 80%;
// }
.content tr td:nth-child(3) {
    width: 553px;
    height: 80px;
}
.reportCgicop .content tr td {
    font-weight: 400;
    color: #333;
    padding-left: 15px;
    border-color: #000;
}
.reportCgicop .content_title, .content tr td {
    font-size: 16px;
    font-family: Microsoft YaHei;
}
.reportCgicop .content tr td .star {
    color: #f56c6c;
    padding-right: 2px;
}
table,table tr th, table tr td { 
  border:1px solid #000; 

}
.reportCgicop .content tr td:nth-child(2) div .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 80%;
}
.reportCgicop .content tr td:nth-child(2) div .el-textarea {
    position: relative;
    display: inline-block;
    width: 80%;
    vertical-align: bottom;
    font-size: 14px;
}
.reportCgicop .el-form-item {
    margin-bottom: 22px;
    margin-top: 22px;
}
</style>

